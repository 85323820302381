<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form size="small">
					<el-form-item :label="$t('teacher.formItem.shopkeeper')">
						<el-input v-model="screenForm.student_name" clearable :placeholder="$t('teacher.placeholder.shopkeeper')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shopName')">
						<el-input v-model="screenForm.store_name" clearable :placeholder="$t('teacher.placeholder.shopName')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.belongClass')">
						<el-select v-model="screenForm.class_id" class="w_all" clearable>
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name"
								:value="item.class_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shopStatus')">
						<el-select v-model="screenForm.store_status" class="w_all" clearable>
							<el-option v-for="(item,index) in statusOption" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
						<span class="search-clear" @click="handleClearSearch"><i
									class="el-icon-brush"></i>{{$t('teacher.actions.clear')}}</span>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-table">
				<el-table :data="pageList" size="small" @sort-change="handleSortPageChange">
					<el-table-column :label="$t('teacher.tableHead.studentAccount')">
						<template slot-scope="scope">
							<div class="user-info">
								<h1>{{$t('teacher.base.student')+':'}}{{scope.row.real_name}}</h1>
								<p>{{$t('teacher.base.account')+':'}}{{scope.row.user_name}}</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.shopName')" prop="store_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.belongClass')" prop="class_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.openTime')" align="center">
						<template slot-scope="scope">{{scope.row.open_time | parseTime()}}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.status')" align="center">
						<template slot-scope="scope">{{scope.row.audit_status | statusFilter($t('teacher.option.shopStatus'))}}</template>
					</el-table-column>
					<!--<el-table-column label="$t('teacher.tableHead.startPay')" align="center">
						<template slot-scope="scope">
							<el-switch v-model="scope.row.open_pay" :active-value="1" :inactive-value="0" @change="handlePaymentChange(scope.row)">
							</el-switch>
						</template>
					</el-table-column>
					<el-table-column label="$t('teacher.tableHead.overallScore')" prop="logins" align="center" sortable="custom"></el-table-column>-->
					<el-table-column :label="$t('teacher.tableHead.actions')" align="center">
						<template slot-scope="scope">
							<ul class="action">
								<li>
									<router-link :to="{name:'teacherShopInfo',params:{id:scope.row.store_id}}">
										{{$t('teacher.actions.view')}}
									</router-link>
								</li>
								<li>
									<router-link :to="{name:'teacherShopEdit',params:{id:scope.row.store_id}}">
										{{$t('teacher.actions.edit')}}
									</router-link>
								</li>
								<!-- <li><a href="javascript:void(0);" @click="handleDelete(scope.row.store_id)">{{$t('teacher.actions.delete')}}</a></li> -->
							</ul>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="t-page-bottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('teacher.pagination.prevText')" :next-text="$t('teacher.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchClass, getShopList, updateShop, deleteShop } from '@/api/teacher'
	
	const defaultScreen = {
		store_name: '',
		student_name: '',
		class_id: '',
		store_status: '',
	}
	
	export default {
		filters: {
			statusFilter(status, option) {
				let statusStr = ''
				option.forEach(item=> {
					if(item.value === status) {
						statusStr = item.label
					}
				})
				return statusStr
			}
		},
		data() {
			return {
				screenForm: Object.assign({}, defaultScreen),
				statusOption: Object.assign([], this.$t('teacher.option.shopStatus')),
				classOption: [],
				pageList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
				sortSign: '',
				sortType: '',
			}
		},
		mounted() {
			this.getClassOption();
			this.getPageList();
		},
		methods: {
			getClassOption() {
				let params = {
					page_index: 0,
					page_size: 1000
				}
				fetchClass(params).then(response => {
					this.classOption = response.data.list;
				})
			},
			getPageList() {
				let params = {
					...this.screenForm,
					sort_sign: this.sortSign,
					sort_type: this.sortType,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				getShopList(params).then(response => {
					this.pageList = response.data.list;
					this.pageTotal = parseInt(response.data.count);
				})
			},
			handleSearch() {
				this.pageCurr = 1;
				this.getPageList();
			},
			handleClearSearch() {
				this.screenForm = Object.assign({}, defaultScreen)
				this.pageCurr = 1;
				this.getPageList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getPageList();
			},
			handleSortPageChange(column) {
				this.sortSign = column.prop
				if (column.order == 'ascending') {
					this.sortType = 'asc'
				} else {
					this.sortType = 'desc'
				}
				this.pageCurr = 1;
				this.getPageList()
			},
			handleDelete(id) {
				this.$confirm(this.$t('teacher.confirm.deleteText'), this.$t('teacher.confirm.title'), {
					confirmButtonText: this.$t('teacher.actions.confirm1'),
					cancelButtonText: this.$t('teacher.actions.cancel1'),
					type: 'warning'
				}).then(() => {
					deleteShop({store_id: id}).then(() => {
						this.$message.success(this.$t('teacher.successMsg.delete'))
					})
				}).catch(() => {
					this.$message(this.$t('teacher.cancelMsg.delete'))
				})
			},
			handlePaymentChange(item){
				updateShop(item).then(() => { })
			},
		},
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;

		.t-page-title {
			padding-bottom: 20px;
			margin-bottom: 20px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.t-page-screen {
			// padding-top: 18px;
			// border-top: 1px solid #EBEEF5;
			::v-deep .el-form {
				display: flex;
				flex-wrap: wrap;
				.el-form-item {
					width: 320px;
					flex-shrink: 0;
					display: flex;
					padding: 0 9px;
					margin-right: 0;
					margin-bottom: 16px;
					box-sizing: border-box;
					.el-form-item__label {
						min-width: 68px;
					}
					.el-form-item__content {
						flex: 1;
						width: 0;
					}
				}
			}
			.w100 {
				width: 100px;
			}
			.w_all {
				width: 100%;
			}

			.search-clear {
				cursor: pointer;
				margin-left: 12px;

				i {
					margin-right: 4px;
				}
			}
		}

		.t-page-table {
			::v-deep .el-table {
				th.el-table__cell {
					background-color: $--seller-thead-background-1;
				}
			}

			.user-info {
				h1 {
					font-size: 14px;
					line-height: 20px;
				}

				p {
					font-size: 12px;
					line-height: 16px;
					color: #999999;
				}
			}

			.action {
				li {
					display: inline-block;
					margin: 0 6px;
				}

				a {
					color: $--seller-link-color;
					&:hover {
						color: $--seller-link-hover;
					}
				}
			}
		}

		.t-page-bottom {
			padding: 15px 0;
			text-align: center;
		}
	}
</style>
